<template>
  <div id="showimg2" class="container pucontainer">
    <div class="putitle">
      <span
        :class="
          datas.position == 1
            ? 'left'
            : datas.position == 3
            ? 'right'
            : ''
        "
        >{{ $i18n.locale == 'en' ? datas.titleen : datas.title }}</span
      >
    </div>
    <div class="flexrowdiv">
      <div
        class="citem"
        v-for="(item, i) in datas.item"
        :key="i"
        @click="golink(item.url)"
      >
        <q-img :src="item.picture" :ratio="20/27" />
      </div>
    </div>
    <div class="c4txt">
      <h5>{{ $i18n.locale == 'en' ? datas.titleen : datas.title }}</h5>
      <p>{{ datas.contents }}</p>
    </div>
  </div>
</template>

<script>
import { golink } from '../index'

export default {
  name: 'showImg2',
  props: {
    datas: {
      type: Object
    }
  },
  setup () {
    return {
      golink
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  margin-top: 70px;
  margin-bottom: 50px;
  min-height: 200px;
}
.citem {
  width: 414px;
  position: relative;
  color: #fff;
  margin-right: 20px;
  cursor: pointer;
  img {
    width: auto;
    height: 573px;
  }
  .ctxt {
    position: absolute;
    left: 0;
    bottom: 0;
    padding: 30px;
    font-size: 30px;
    text-align: left;
    p {
      font-size: 14px;
      margin-top: 5px;
      color: #fff;
    }
  }
}
.citem:last-child {
  margin-right: 0;
}
.flexrowdiv {
  display: flex;
  justify-content: center;
}
.c4txt {
  margin-top: 30px;
  text-align: center;
  h5 {
    font-size: 25px;
    margin-bottom: 5px;
  }
}
</style>
